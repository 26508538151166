import './scss/index.min.css';

// import objectFitImages from 'object-fit-images';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import 'core-js/stable/promise';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import Bowser from 'bowser';

import { isIE, isSafari } from 'utils';
import ServiceWorkerNotice from 'components/ServiceWorkerNotice';

import('config/i18n');
import('dayjs/locale/de');
const PolyfillLoader = React.lazy(() => import('./PolyfillLoader'));
const App = React.lazy(() => import('./App'));

const browser = Bowser.getParser(window.navigator.userAgent);
const isModernBrowser = browser.satisfies({
  chrome: '>65',
  edge: '>80',
  firefox: '>69',
  opera: '>70',
});

if (isIE()) {
  document.body.classList.add('is-ie');
}
if (isSafari) {
  document.body.classList.add('is-safari');
}

ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback={null}>
    {!isModernBrowser ? (
      <PolyfillLoader>
        <App />
      </PolyfillLoader>
    ) : (
      <App />
    )}
    <ServiceWorkerNotice />
  </Suspense>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.unregister();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
